import { Calendar, Card, Input, Modal, Row, Select, Table } from 'antd';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
  .ant-table-cell,
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 0 5px !important;
    white-space: nowrap; /* Prevents text from wrapping */
  }
  
  .ant-table {
    color: black;
    table-layout: auto !important;
    // table-layout: fixed !important;
  }

  .ant-table-content {
    overflow-x: auto; 
  }
  
`;

export const StyledClientTable = styled(Table)`
  .ant-table-cell,
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 0 0px !important;
    white-space: nowrap; /* Prevents text from wrapping */
  }
  
  .ant-table {
    color: black;
    font-size:16px;
    table-layout: auto !important;
  }

  .ant-table-content {
    overflow-x: auto; 
  }
`;

export const StyledStandUpTable = styled(Table)`
  .ant-table-cell,
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 4px !important;
    white-space: nowrap; /* Prevents text from wrapping */
  }
  
  .ant-table {
    color: black;
    font-size:14px;
    table-layout: auto !important;
  }

  .ant-table-content {
    overflow-x: auto; 
  }
`;

export const StyledOffersTable = styled(Table)`
  .ant-table-tbody > tr > td {
    padding: 4px !important;
    white-space: nowrap; /* Prevents text from wrapping */
  }
  
  .ant-table {
    color: black;
    font-size:14px;
    table-layout: auto !important;
  }

  .ant-table-content {
    overflow-x: auto; 
  }
`;

export const StyledSelect = styled(Select)`
    .ant-select-selector {
        height: 24px;
        padding: 0 8px;
        font-size: 12px;
        display: flex;
        align-items: center;
    }

    .ant-select-selection-item {
        line-height: 22px;
    }

    .ant-select-arrow {
        top: 50%;
        transform: translateY(-50%);
    }
`;

export const StyledInput = styled(Input)`
  height: 24px;
  font-size: 12px;
  display: flex;
  align-items: center;

  .ant-input {
  margin:0px
    height: 100%;
    line-height: 1.2;
    }
`;

export const StyledInfinityScrollTable = styled(Table)`
  .ant-table-cell,
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 0 2px !important;
    // white-space: nowrap; /* Prevents text from wrapping */
  }
  
  .ant-table {
    color: black;
    table-layout: auto !important;
    // table-layout: fixed !important;
  }

  .ant-table-content, .ant-table-header {
    overflow: auto !important; 
  }

  .gx-badge{
    white-space: normal;
    padding: 0px 0px 4px;
  }
  
`;

export const StyledCalendarTable = styled(Table)`
  .ant-table-cell,
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    // padding: 0 0px !important;
     border-bottom: 1.9px solid #e8e8e8
    // white-space: nowrap; /* Prevents text from wrapping */
  }
    .ant-table-cell {
    vertical-align: bottom; 
      height: 62px; /* Corrected typo from hight to height */
      }
      .ant-table-thead > tr > th {
        // font-weight: bold;
        vertical-align: bottom; 
        height: 83px;
        background-color: white;
        // color: blue; /* Example of changing text color */
        padding:7px 16px
      }
  .ant-picker-body{
    padding-bottom: 0px;
  }
  .ant-table {
    margin-top:1px
    // table-layout: auto !important;
    // table-layout: fixed !important;
  }

  .ant-table-content {
    // overflow-x: auto; 
  }
`;

export const StyledAccountOfferTable = styled(Table)`
  .ant-table-cell,
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    // padding: 0 0px !important;
    // white-space: nowrap; /* Prevents text from wrapping */
  }
    .ant-table-cell {
      vertical-align: bottom; 
    }
      .ant-table-thead > tr > th {
        // font-weight: bold;
        // vertical-align: bottom; 
        // height: 100px;
        // background-color: white;
        // color: blue; /* Example of changing text color */
        // padding:7px 16px
      }
  
  .ant-table {
    margin-top:1px
    // table-layout: auto !important;
    // table-layout: fixed !important;
  }

  .ant-table-content {
    // overflow-x: auto; 
  }
`;

export const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
  .ant-form-item{
    margin: 0 !important; /* Adjust the value as needed */
  }
`;

export const StyledMergeClintCard = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
  .ant-table-wrapper{
    margin-left: 0px;
  }
  .ant-form-item{
    margin: 0 !important; /* Adjust the value as needed */
  }
`;

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 20px 20px;
  }
  .ant-table-wrapper{
    margin-left: 0px;
  }
  .ant-modal-header{
    // border-bottom:none
  }
`;

export const StyledPayeeCard = styled(Card)`
  .ant-card-body {
    padding: 15px;
  }
`;

export const StyledRow = styled(Row)`
  .ant-row{
    margin: 0 !important; 
}
`;

export const StyledCalendar = styled(Calendar)`
  .ant-picker-calendar-date-content{
    height: 32px !important;
  }
  .ant-picker-calendar-date-value {
    font-size: 12px; /* Adjust the size as needed */
}
`;