import {
    ACCOUNT_RECEIVABLE_LIST,
    ALL_OFFERS_EXCLUDING_JOINGING_PENDING_LIST,
    ALL_OFFERS_EXCLUDING_JOINGING_PENDING_LIST_NO_PAGINATION,
    FETCH_ACCOUNT_CONSULTANT_LIST_SUCCESS,
    FETCH_ACCOUNT_CONSULTANT_OBJECT_SUCCESS,
    FETCH_ACCOUNT_CONSULTANT_OFFER_LIST_SUCCESS,
    FETCH_ACCOUNT_CONSULTANT_ON_OFFERS_LIST_SUCCESS,
    FETCH_ACCOUNT_OFFER_CONSULTANT_PAYEE_LIST_SUCCESS,
    FETCH_APPROVED_HOURS_LIST_SUCCESS,
    FETCH_CLOSED_ACCOUNT_OFFER_LIST_SUCCESS,
    FETCH_INVOICE_NUMBER_LIST_SUCCESS,
    FETCH_ONGOING_ACCOUNT_OFFER_LIST_SUCCESS,
    FETCH_SINGLE_ACCOUNT_RECEIVABLE_DATA,
    FETCH_TRANSACTION_LIST_SUCCESS,
    FETCH_TRANSATION_LIST_FROM_INVOICE_NUMBER,
    GET_CONSULTANTS_SUCCESS,
  } from "../../constants/ActionTypes";
  
  
  const INIT_STATE = {
    accountReceivableList:null,
    totalAccountReceivableCount:0,
    allOffersOtherThenJp:null,
    totalAllOffersOtherThenJpCount:0,
    singleAccountReceivableData:null,
    allOffersOtherThenJpNoPagination:null,
    accountsConsultantList:null,
    totalAccountsConsultantCount:0,
    accountsConsultant:null,
    accountsConsultantOffersList:null,
    totalAccountsConsultantOffers:0,
    accountsOngoingOffersList:null,
    totalAccountsOngoingOffers:0,
    accountsClosedOffersList:null,
    totalAccountsClosedOffers:0,
    accountsOffersConsultantPayeeList:null,
    approvedHours:null,
    transactionList:null,
    totalTransactionCount:0,
    invoiceNumberList:null,
    transactionListFromInvoiceNumber:null,
    assignedConsultantsOnOffersList:null
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACCOUNT_RECEIVABLE_LIST:{
            return {
                ...state,
                accountReceivableList: action.payload.results,
                totalAccountReceivableCount: action.payload.count,
            }
        }
        case ALL_OFFERS_EXCLUDING_JOINGING_PENDING_LIST:{
            return {
                ...state,
                allOffersOtherThenJp: action.payload.results,
                totalAllOffersOtherThenJpCount: action.payload.count,
            }
        }
        case ALL_OFFERS_EXCLUDING_JOINGING_PENDING_LIST_NO_PAGINATION:{
            return {
                ...state,
                allOffersOtherThenJpNoPagination: action.payload,
            }
        }
        case FETCH_SINGLE_ACCOUNT_RECEIVABLE_DATA:{
            return {
                ...state,
                singleAccountReceivableData: action.payload,
            }
        }
        case FETCH_ACCOUNT_CONSULTANT_LIST_SUCCESS:{
            return {
                ...state,
                accountsConsultantList: action.payload.results,
                totalAccountsConsultantCount: action.payload.count,
            }
        }
        case FETCH_ACCOUNT_CONSULTANT_ON_OFFERS_LIST_SUCCESS:{
            return {
                ...state,
                assignedConsultantsOnOffersList: action.payload,
            }
        }
        case FETCH_ACCOUNT_CONSULTANT_OBJECT_SUCCESS:{
            return {
                ...state,
                accountsConsultant: action.payload,
            }
        }
        case FETCH_ACCOUNT_CONSULTANT_OFFER_LIST_SUCCESS:{
            return {
                ...state,
                accountsConsultantOffersList: action.payload.results,
                totalAccountsConsultantOffers: action.payload.count,
            }
        }
        case FETCH_ONGOING_ACCOUNT_OFFER_LIST_SUCCESS:{
            return {
                ...state,
                accountsOngoingOffersList: action.payload.results,
                totalAccountsOngoingOffers: action.payload.count,
            }
        }
        case FETCH_CLOSED_ACCOUNT_OFFER_LIST_SUCCESS:{
            return {
                ...state,
                accountsClosedOffersList: action.payload.results,
                totalAccountsClosedOffers: action.payload.count,
            }
        }
        case FETCH_ACCOUNT_OFFER_CONSULTANT_PAYEE_LIST_SUCCESS:{
            return {
                ...state,
                accountsOffersConsultantPayeeList: action.payload,
            }
        }
        case FETCH_APPROVED_HOURS_LIST_SUCCESS:{
            return {
                ...state,
                approvedHours: action.payload,
            }
        }
        case FETCH_TRANSACTION_LIST_SUCCESS:{
            return {
                ...state,
                transactionList : action.payload.results,
                totalTransactionCount : action.payload.count,
            }
        }
        case FETCH_INVOICE_NUMBER_LIST_SUCCESS:{
            return {
                ...state,
                invoiceNumberList: action.payload,
            }
        }
        case FETCH_TRANSATION_LIST_FROM_INVOICE_NUMBER:{
            return {
                ...state,
                transactionListFromInvoiceNumber: action.payload.results,
            }
        }
        
        default:
            return state;
        }
    }
  