import IntlMessages from "util/IntlMessages";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import React from "react";
import {useDispatch} from "react-redux";
import {THEME_TYPE_LITE} from "../../constants/ThemeSetting";
import moment from "moment";
import { DeleteKeysFromSession } from "../../routes/recruitment/screening/utils";
import { fetchConsultantAccountDetailsList, fetchConsultantOffersList } from "../../appRedux/actions";

const ConsltantSidebar = ({navStyleClass, defaultOpenKeys, selectedKeys, themeType, user}) => {
  const dispatch = useDispatch();

  const keysToDeleteFromSession = ['pageSize','employeePageNo','profilePageNo','submissionPageNo',
    'assessmentPageNo','clientPagination','pocPageNo','ipPageNo','transferRequestPageNo','offersPageNo',
    'supportPersonsoffersPageNo','ongoingTablePageNumber','accountDetatailPage']
    
  const fetchDashboardData = () => {
    dispatch(fetchConsultantOffersList({page:1}))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }

  const fetchAccountDetails = () => {
    dispatch(fetchConsultantAccountDetailsList({page: 1}))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }

  return (
        <Menu defaultOpenKeys={[defaultOpenKeys]} selectedKeys={[selectedKeys]} theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'} mode="inline">
          <Menu.Item key="consultant.dashboard">
            <Link to={"/username/dashboard".replace('username', user.username)}
                  onClick={()=>fetchDashboardData()}>
              <span><i className="icon icon-auth-screen"/><IntlMessages id="Dashboard"/></span>
              </Link>
          </Menu.Item>
          <Menu.Item key="consultant.account">
            <Link to={"/username/consultant/account-details".replace('username', user.username)}
                  onClick={()=>fetchAccountDetails()}>
              <span><i className="icon icon-auth-screen"/><IntlMessages id="sidebar.listType.account-details"/></span>
              </Link>
          </Menu.Item>
        </Menu>
);
};
export default ConsltantSidebar;
