import React, {useEffect, useState } from 'react'
import {editOffer, fetchSupportIdList, handleDisableSubmitButton, fetchProjectCoordinatorsList, fetchSupportPersonsList, editProjectCoordinatorsOfferSupports, fetchSupportPersonsOptionList} from '../../appRedux/actions';
import {Col, Form, Input, Modal, Select, DatePicker, Checkbox, message} from "antd";
import moment from 'moment';
import { useSelector } from 'react-redux';
import { groups, levelsConst } from '../../constants/Constants';
import { offersStatusFilters } from '../../constants/FilterData';
import { status } from 'nprogress';
import { EmployerCompanyOptions } from '../../constants/ConstantOptions';
import { StyledRow } from '../consultant/Table/StyledTableComponent/StyledConsultantFixedheaderTable';

const EditOfferForm = ({visible, setVisible,  dispatch, user, submissions=[], title, record, showSelectSubmission=true, 
    filterBy={}, pageSize, currentPage, onGoingOffers
  }) => {
    const selectedRecord = record 
    const loggedInUser = useSelector(({user}) => user.loggedInUser);
    const [currentEmployerCompany, setCurrentEmployerCompany] = useState("");
    const [form] = Form.useForm();
    const {TextArea} = Input;
    const {Option} = Select;
    const [currentProject, setCurrentProject] = useState(null);
    const [supportPersonList, setSupportPersonList] = useState(null);
    const [showOfferToconsultant, setShowOfferToconsultant] = useState(false);
    const errorForm = useSelector(({common}) => common.error_response);
    const supportIdList = useSelector(({employees}) => employees.supportIdList);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const disableSubmitButton = useSelector(({common}) => common.disableSubmitButton);
    const { projectCoordinatorsNoPaginationList ,supportPersonOptionList } = useSelector(({offer})=>offer);

    const [seletedSupportPersonsList, setSelectedSupportPersonsList] = useState(null)
    const [supportPersonsListWithEndDate, setSupportPersonsListWithEndDate] = useState(null)

  useEffect(()=>{
    if(selectedRecord && selectedRecord.support && supportPersonList){
      if(selectedRecord.support){
      const selectedSupportIds = selectedRecord.support.filter((obj) => obj.end_date === null).map((obj) => obj.support.id);
      form.setFieldsValue({ support_person: selectedSupportIds });
      // setSelectedSupportPersonsList(selectedRecord.support);
        if(selectedSupportIds){
          const supportWithoutEndDate = selectedRecord.support.filter((obj) => ((selectedSupportIds.includes(obj.support.id) && !obj.end_date)))
          setSelectedSupportPersonsList(supportWithoutEndDate);
        }
      }
    }
  },[selectedRecord,supportPersonList])

  useEffect(()=>{ // its important here 
    if(selectedRecord && selectedRecord.support){
      const innitialSupportWithEndDate = selectedRecord.support.filter((item)=>{
        if(item && item.end_date !== null){
            return item;
        }
      })
      if (innitialSupportWithEndDate && innitialSupportWithEndDate.length > 0) {
        const initialValues = {};
        innitialSupportWithEndDate.forEach((record, index) => {
          initialValues[`innit_supportName_${record.support.id}_${index}`] = record&&record.support&&record.support.id;
          initialValues[`innit_start_date_${record.support.id}_${index}`] = record.start_date ? moment(record.start_date) : null;
          initialValues[`innit_end_date_${record.support.id}_${index}`] = record.end_date ? moment(record.end_date) : null;
          initialValues[`innit_rate_${record.support.id}_${index}`] = record.rate;
        });
        form.setFieldsValue(initialValues);
      }
      setSupportPersonsListWithEndDate(innitialSupportWithEndDate)
    }
  },[selectedRecord, supportPersonList])

  const failedUpdateSupport = (errorResponse) => {
    if (errorResponse && errorResponse.support) {
      errorResponse.support.forEach((errorObj, index) => {
        if (Object.keys(errorObj).length === 0) {
          return;
        }

      const supportIdKey = Object.keys(errorObj)[0];
      const isSupportIdError = !Array.isArray(errorObj[supportIdKey]) && typeof errorObj[supportIdKey] === 'object';
      const supportId = supportIdKey;
        if (isSupportIdError) {
          const fieldErrors = errorObj[supportId];

          Object.keys(fieldErrors).forEach((field) => {
            const errorMessage = fieldErrors[field];
            const fieldName = `${field}_${supportId}_${index}`;
            message.error(`${errorMessage}`)
            form.setFields([
              {
                name: fieldName,
                errors: [errorMessage],
              },
            ]);
          });
        } else {
          Object.keys(errorObj).forEach((field) => {
            const errorMessages = errorObj[field]; 
            const fieldName = `${field}_${supportId}_${index}`;
            message.error(`${errorMessages}`)
            form.setFields([
              {
                name: fieldName,
                errors: errorMessages, 
              },
            ]);
          });
        }
      });
    }
  };

  const submitUpdateSupport = (formValue) => {
    const values = formValue
    if(user && user.groups && user.groups.includes('Admin')){
    if(values){
      let supportArray = [];
      Object.keys(values).forEach(key => {
        if (key.startsWith("start_date_")) {
          const [, date, supportId, index] = key.split('_');
          const foundItem = selectedRecord && selectedRecord.support && selectedRecord.support.find(function (item) {
            return item.support.id === parseInt(supportId) && values[key] && moment(values[key]).format('YYYY-MM-DD') === item.start_date;
          });
          const supportItem = selectedRecord && selectedRecord.support && selectedRecord.support.find(item => {
            return item.support.id == parseInt(supportId);
          });
          
          const isStartDateChanged = supportItem && values[key] && moment(values[key]).format('YYYY-MM-DD') !== supportItem.start_date;
          const hasEndDate = supportItem && supportItem.end_date;
          const existingSupportChangeId = (isStartDateChanged && !hasEndDate) ? supportItem.id : null;

          const supportObject = {
            ...(foundItem && foundItem.id && {id: foundItem ? foundItem.id : null}),
            ...(existingSupportChangeId && {id: existingSupportChangeId ? existingSupportChangeId : null}),
            support: supportId && parseInt(supportId),
            start_date: values[key]?moment(values[key]).format('YYYY-MM-DD'):null,
            end_date: values[`end_date_${supportId}_${index}`]?moment(values[`end_date_${supportId}_${index}`]).format('YYYY-MM-DD'):null,
            rate: values[`rate_${parseInt(supportId)}_${index}`]?values[`rate_${parseInt(supportId)}_${index}`]:0,
            support_name: values[`supportName_${supportId}_${index}`] 
          };

          supportArray.push(supportObject);
        }
      });

      const initialSupportsId = supportArray.map((object) => object.id) // Extract ID
          .filter(id => id !== undefined); // Filter out undefined values

      const selectedRecordSupportIds = selectedRecord.support.map((supportItem) => {
        if (!supportItem.end_date) {
          return supportItem.id;
        }
        return null; 
      }).filter(id => id !== null);

      const allSupportsExist = selectedRecordSupportIds.every((id) => initialSupportsId.includes(id));
      if (supportPersonsListWithEndDate && supportPersonsListWithEndDate.length > 0) {
        supportPersonsListWithEndDate.forEach((item) => {
          const data = {
            id: item.id,
            end_date: item.end_date,
            start_date: item.start_date,
            support: item.support.id,
            rate: item.rate,
          };
          supportArray.push(data);
        });
      }
      const payloadToSend = {
          support: supportArray
      }
      if (!allSupportsExist) {
        message.error('Some support are missing. Please ensure all required supports are present.');
        return; 
      }
      
      dispatch(handleDisableSubmitButton('edit_project_coordinators_offers_form',true))
        if(supportArray && supportArray.length > 0){ 
          dispatch(editProjectCoordinatorsOfferSupports(selectedRecord.id, payloadToSend, 
            { callFunctionOnResponce: onCreate, formValue: formValue, showErrorIfFaild: failedUpdateSupport}))
        }else{
          onCreate(values)
        }
      }
    }else{
      onCreate(values)
    }
  }

  const handleSelectSupportPerson = (value) => {
    if (value && selectedRecord) {
      const selectedSupportsObject = [];
  
      if (selectedSupportsObject.length === 0) {
        const fallbackSupports = supportPersonList
          .filter(item => value.includes(item.id)) // Filter selected support persons
          .map(obj => {
            const existingSupport = seletedSupportPersonsList 
              ? seletedSupportPersonsList.find(iteam => iteam.id === obj.id) 
              : null;
      
            const innitialSupportData = selectedRecord && selectedRecord.support 
              ? selectedRecord.support.find(iteam => iteam.support.id === obj.id) 
              : null;
      
            const isInSupportList = supportPersonsListWithEndDate && supportPersonsListWithEndDate
              .some(supportItem => supportItem.support.id === obj.id);

            return {
              id: obj.id,
              support: {
                name: obj.name,
                id: obj.id,
              },
              rate: existingSupport && existingSupport.rate 
                ? existingSupport.rate 
                : (innitialSupportData && innitialSupportData.rate 
                  ? innitialSupportData.rate 
                  : obj.rate ? obj.rate : null),
      
              start_date: existingSupport && existingSupport.start_date 
                ? existingSupport.start_date 
                : (obj.start_date 
                  ? obj.start_date 
                  : (innitialSupportData && (innitialSupportData.start_date && !isInSupportList )
                    ? innitialSupportData.start_date 
                    : null)),
      
              end_date: existingSupport && existingSupport.end_date 
                ? existingSupport.end_date 
                : (obj.end_date 
                  ? obj.end_date 
                  : (innitialSupportData && innitialSupportData.end_date && !isInSupportList 
                    ? innitialSupportData.end_date 
                    : null)),
            };
          });

        setSelectedSupportPersonsList(fallbackSupports);
      } else {
        setSelectedSupportPersonsList(selectedSupportsObject);
      }
    }
  };
  
  const handleChangeInSelectedSupport = (changedValue, record, changedKeyName) => {
    const selectedSupportCopy = [...seletedSupportPersonsList];
    if (record && selectedSupportCopy) {
      const updatedSupportList = selectedSupportCopy.map((item) => {
        if ((item.id === record.id) && ['start_date','end_date'].includes(changedKeyName)) {
          return {
            ...item,
            [changedKeyName]: changedValue ? moment(changedValue).format('YYYY-MM-DD') : null 
          };
        }else if(item.id === record.id){
          return {
            ...item,
            [changedKeyName]: changedValue ? changedValue : null 
          };
        }
        return item;
      });
      selectedRecord.support = selectedRecord.support.map((supportItem) => {
        if (supportItem.id === record.id) {
          return {
            ...supportItem,
            [changedKeyName]: ['start_date', 'end_date'].includes(changedKeyName)
              ? (changedValue ? moment(changedValue).format('YYYY-MM-DD') : null)
              : (changedValue || null),
          };
        }
        return supportItem;
      });
      setSelectedSupportPersonsList(updatedSupportList);
    } 
  }

  useEffect(() => {
    if (seletedSupportPersonsList && seletedSupportPersonsList.length > 0) {
      const initialValues = {};
      seletedSupportPersonsList.forEach((record, index) => {
        initialValues[`supportName_${record.support.id}_${index}`] = record&&record.support&&record.support.name;
        initialValues[`start_date_${record.support.id}_${index}`] = record.start_date ? moment(record.start_date) : null;
        initialValues[`end_date_${record.support.id}_${index}`] = record.end_date ? moment(record.end_date) : null;
        initialValues[`rate_${record.support.id}_${index}`] = record.rate;
      });
      form.setFieldsValue(initialValues);
    }
  }, [seletedSupportPersonsList, form]);

    useEffect(()=>{
      if(record && record.employer_company ==='Other') {
        setCurrentEmployerCompany('Other')
      }else{
        setCurrentEmployerCompany('')
      }
    },[record])

    useEffect(()=>{
      if(!projectCoordinatorsNoPaginationList){
        dispatch(fetchProjectCoordinatorsList({all:true}));
      }
    },[])

    useEffect(()=>{
      if(supportPersonOptionList){
        setSupportPersonList(supportPersonOptionList)
      }
    },[supportPersonOptionList])

    const handleCancel = () => {
        // dispatch(hideModal());
        setVisible(false);
        form.resetFields();
      };
      
      function formatDateToYYYYMMDD(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const projectTypeMapping = {
        '1099-Business': '1099-b',
        '1099-Individual': '1099-i',
        'In-House': 'inhouse',
        Outsource: 'outsource',
        C2C: 'c2c',
        W2: 'w2',
      };

    const handleShowOfferToConsultantCheckBox = (e) => {
      const value = e.target.value
      if(value){
        setShowOfferToconsultant(value)
      }
    }

      useEffect(() => {
        if (record && record.employer_company && record.employer_company !== "Other" && EmployerCompanyOptions) {
          const filterTheDataInrecord = EmployerCompanyOptions.some(
            element => element.handle === record.employer_company
          );
          if (!filterTheDataInrecord) {
            setCurrentEmployerCompany("Other");
            form.setFields([
              { name: 'other_employer_company_name', value: record.employer_company },
              { name: 'employer_company', value: "Other" }
            ]);
          }
          else{
            setCurrentEmployerCompany('');
            form.resetFields(['other_employer_company_name'])
            form.setFields([
              { name: 'employer_company', value: record.employer_company }
            ]);
          }
        }
      }, [record, EmployerCompanyOptions, setCurrentEmployerCompany, form]);
    

    useEffect(() => {
        if (record && supportIdList) {
            const mappedProjectType = projectTypeMapping[record.project_type] || record.project_type;
          if(mappedProjectType === "inhouse"){
            setCurrentProject("inhouse");
          }
          else{
            setCurrentProject(null)
          }
          const initialSupport = supportIdList.find(item => item.user_id === record.inhouse_engineer);
          const initialSupportId = initialSupport ? initialSupport.user_id : null

          form.setFieldsValue({
            submission: `${record.consultant ? record.consultant : ''} :: ${record.vendor ? record.vendor : ''} :: ${record.client ? record.client : ''} :: ${record.job_title ? record.job_title : ''}` ,
            project_rate: record.project_rate,
            offered_date: moment(record.offered_date, "YYYY-MM-DD"), // Use moment to parse and format the date
            project_start_date: record.project_start_date?moment(record.project_start_date, "YYYY-MM-DD"): null,
            project_end_date: record.project_end_date?moment(record.project_end_date, "YYYY-MM-DD"):null,
            project_type: mappedProjectType,
            project_status: record.project_status,
            inhouse_engineer: initialSupportId,
            inhouse_engg_rate: record.inhouse_engg_rate?record.inhouse_engg_rate:0,
            device_handling_rate: record.device_handling_rate,
            project_coordinator: record && record.project_coordinator,
            // employer_company : record && record.employer_company ? record.employer_company:null,
            // other_employer_company_name: record && record.other_employer_company_name?record.other_employer_company_name:null,
            remark: record.remark,
            payroll: record.payroll,
            payroll_cost: record.payroll_cost,
            support_rate: record.support_rate,
            payroll_tax_type:record.payroll_tax_type,
            consultant_rate: record.consultant_rate,
            tax_term: record.tax_term,
            // support_person:record && record.support_person && record.support_person.id,
            // consultant_can_view:   showOfferToconsultant,
            consultant_can_view: record.consultant_can_view ,
            technologies: record.technologies ? (typeof record.technologies === 'string' ? JSON.parse(record.technologies) : record.technologies.join(", ")) :"",
          });
    
          // if(record && record.project_coordinator){
          //   dispatch(fetchSupportPersonsList({pagination:false ,project_coordinator_id:record.project_coordinator}))
          // }else{
          //   setSupportPersonList(null)
          // }
          // Handle project type change if needed
          if (record.project_type === "inhouse") {
            setCurrentProject("inhouse");
          }
        }
      }, [record, visible]);

  useEffect(()=>{
    if(!supportPersonOptionList && user && user.groups && user.groups.includes('Admin')){
      dispatch(fetchSupportPersonsOptionList())
    }
  },[])
  
      useEffect(() => {
        if(errorForm && errorForm.editOffer && errorForm.editOffer =='success'){
          handleCancel();
        }
        else if(errorForm && errorForm.editOffer  && errorForm.editOffer.data && errorForm.editOffer.data.errors) {
           const errorFields = Object.keys(errorForm.editOffer.data.errors).map(key => ({
                name: key,
                errors: errorForm.editOffer.data.errors[key],
            }));
            form.setFields(errorFields);
        }
      }, [errorForm]);

    const [ checkedOutBox ,setCheckOutBox] = useState(false)

      const handleCheckBox = (e) => {
        setCheckOutBox(e.target.checked)
   }

  const onCreate = values => {
        if (typeof values.technologies === "string") {
          values['technologies'] = values.technologies?values.technologies.split(",").map((tech)=>{return tech.trim()}):[];
        }
        if(values && values.consultant_can_view === undefined){
          values['consultant_can_view'] = false
        }
        const prefixes = ["innit_", "start_", "end_", "supportName_", "rate_", "support_person"];

        Object.keys(values).forEach(key => {
          if (prefixes.some(prefix => key.startsWith(prefix))) {
            delete values[key];
          }
        });
        for (let key in values) {
          if(key !== 'consultant_can_view'){
            if (values[key] == undefined || values[key] =='undefined' || values[key] == "" ||values[key] == " ") {
              delete values[key];
            }
          }
        }
        if (values.hasOwnProperty('offered_date')) {
            values.offered_date = formatDateToYYYYMMDD(values.offered_date);
        }
        if (values.hasOwnProperty('project_start_date')) {
            values.project_start_date = formatDateToYYYYMMDD(values.project_start_date);
        }else{
          values.project_start_date = null;
        }
        if (values.hasOwnProperty('project_end_date')) {
            values.project_end_date = formatDateToYYYYMMDD(values.project_end_date);
        }else{
          values.project_end_date = null
        }
        if(title === "Approve Offer"){
          if(checkedOutBox){
            values.project_start_date_acknowledged = checkedOutBox
          }
          else{
            values.project_start_date_acknowledged = false
          }
        }
        if(values.project_status.length !== 1){
            values.project_status = values.project_status[0].toLowerCase()
        } 
        if(values.project_type){
         values.project_type = values.project_type.replace(/^\w+/g, match => match.toLowerCase())
        }
        if(values['employer_company'] === "Other" ){
          values['employer_company'] = values['other_employer_company_name']
          delete values['other_employer_company_name']
        }
        dispatch(editOffer(record.id, values, {updateTableCellOnResponse: ()=>{}, currentAppliedFilters: {...filterBy, page_size:pageSize, page:currentPage }}));  // currentAppliedFilters is used to fetch the list with the existing filters
        dispatch(handleDisableSubmitButton('edit_offers_form',true))
        setCheckOutBox(false)
      };
    const handleProjectChange = (value)=>{
        setCurrentProject(value)
    }
  const [supportPersonSearchInputValue, setSupportPersonSearchInputValue] = useState(null)

  // useEffect(()=>{
  //   const deounceTimer = setTimeout(()=>{
  //     dispatch(fetchSupportPersonsList({pagination:false , name: supportPersonSearchInputValue}))
  //   },300)
  //   return ()=> clearTimeout(deounceTimer) 
  // },[supportPersonSearchInputValue])

  // const handleSupportPersonSearch = (inputValue) => {
  //   if(inputValue && inputValue.length > 2){
  //     setSupportPersonSearchInputValue(inputValue)
  //   }
  // }

  // const handleProjectCoordinatorChange = (value) => {
  //   if(value){
  //     dispatch(fetchSupportPersonsList({pagination:false, project_coordinator_id: value}))
  //   }
  // }

    useEffect(()=>{
      setCheckOutBox(false)
      if(!supportIdList){
        dispatch( fetchSupportIdList({all:true}))
      }
    },[])
     
    const handleEmployerCompanyChange = (value)=>{
      setCurrentEmployerCompany(value);
    }
     
  useEffect(()=>{
    if(disableSubmitButton && disableSubmitButton.edit_offers_form && disableSubmitButton.edit_offers_form !==undefined ){
      setIsButtonDisabled(disableSubmitButton.edit_offers_form)
    }
    else{
      setIsButtonDisabled(false)
    }
  },[disableSubmitButton])

  return (
    <Modal
    key={'editModal'}
    open={visible}
    title={title}
    okText="Submit"
    width={700}
    cancelText="Cancel"
    onCancel={() => handleCancel()} 
    okButtonProps={{ disabled: isButtonDisabled }}
    onOk={() => {
      form
        .validateFields()
        .then(values => {
          submitUpdateSupport(values);
        })
        .catch(info => {
          console.log('Validate Failed:', info);
        });
    }}
  >
    <Form
      layout="inline"
      form={form}
      name="consultant_basic"
      className="gx-signin-form gx-form-row0">
       {showSelectSubmission && submissions && <Col span={24}>
            <Form.Item label="Submission" name={"submission"}
              rules={[{required: true, message: 'Please choose a submission!'}]} >
                <Select showSearch
                  optionFilterProp="children" 
                    filterOption={(input, option) =>
                      String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {submissions.map((submission)=>(
                        <Option key={submission.id} value={submission.id}>{submission.consultant_name} :: {submission.vendor_name} :: {submission.client_name} :: {submission.job_title}</Option>
                    ))}
                </Select>
            </Form.Item>
        </Col>}
        <Col span={24}>
            <Form.Item label="Project Type" name="project_type" rules={[{required: true, message: 'Please select a project type!'}]}>
                <Select onChange={handleProjectChange}>  
                    <Option key={'inhouse'} value={'inhouse'}>In-House</Option>
                    <Option key={'outsource'} value={'outsource'}>Outsource</Option>
                    <Option key={'regular'} value={'regular'}>Regular</Option>
                </Select>
            </Form.Item>
        </Col>
        <Col span={24}>
            <Form.Item label="Employer Company" name="employer_company" >
                <Select 
                  showSearch
                  optionFilterProp="children" 
                  filterOption={(input, option) =>
                    String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={handleEmployerCompanyChange}
                >  
                    {EmployerCompanyOptions.map((item)=>{
                        return <Option key={item.handle} value={item.handle}>{item.title}</Option>
                    })}
                </Select>
            </Form.Item>
        </Col>  
          { currentEmployerCompany  === 'Other' &&
            <Col span={24}>
              <Form.Item label='Name of other employer company'
                        rules={[{required: true, message: 'Please enter a employer company name!'}]} 
                        name={"other_employer_company_name"} 
                  >
                <Input type="text" autoComplete="off" />
              </Form.Item> 
            </Col>
          }
        {loggedInUser &&  loggedInUser.level <= levelsConst['Associate Team Lead'] && <Col span={24}>
        <Form.Item label='Project Rate'
                   rules={[{required: true, message: 'Please enter a valid project rate!'}]} 
                   name={"project_rate"}>
          <Input type="number" autoComplete="off" min={0} />
        </Form.Item> 
        </Col>}

        <Col span={24}>
        <Form.Item label='Status' name="project_status" initialValue={record && record.project_status}>
                <Select
                    showSearch 
                    optionFilterProp="children" 
                      filterOption={(input, option) =>
                        String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        options={offersStatusFilters?offersStatusFilters.filter((item)=>item.title !== "All").map((item) => ({
                          key: item.handle,
                          value: item.handle,
                          label: item.title,
                        })):null}
                >
                </Select>
                </Form.Item>
        </Col>
      <Col span={24}>
      <Form.Item label='Date of Offer' name="offered_date"   rules={[{required: true, message: 'Please select a offer received date!'}]}  >
        <DatePicker
          format="MM/DD/YYYY"
          style={{ width: '100%' }}
          disabled={(record && (record.project_status === 'Completed' || record.project_status === 'Ongoing')) || (record && record.project_status !== 'Pending')}  
        />
      </Form.Item>
      </Col>

      <Col span={24}>
      <Form.Item label='Project Start Date' name="project_start_date"   
        // rules={[{required: true, message: 'Please enter a valid project start date!'}]}
       >
        <DatePicker
          format="MM/DD/YYYY"
          style={{ width: '100%' }}
          disabled={record && (record.project_status === 'Completed' || record.project_status === 'Ongoing')}
        />
      </Form.Item>
      </Col>

      <Col span={24}>
      <Form.Item label='Project End Date' name="project_end_date"
        // rules={[{required: true, message: 'Please enter a valid project end date!'}]}
       >
        <DatePicker
          format="MM/DD/YYYY"
          style={{ width: '100%' }}
          disabled={record && record.project_status === 'Completed'}
        />
      </Form.Item>
      </Col>
        
       {currentProject == 'inhouse'? <Col span={24}>
       <Input.Group>
            <Form.Item label="Inhouse engineer" name="inhouse_engineer"  rules={[{required: true, message: 'Please add a support id!'}]}>
                    <Select
                      showSearch
                      allowClear
                      placeholder="Select Inhouse engineer"
                      options={supportIdList?supportIdList.map((item) => ({
                      key: item.user_id,
                      value: item.user_id,
                      label: item.name,
                    })):null}
                    optionFilterProp="label" 
                      filterOption={(input, option) =>
                        String(option.label).toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                    </Select>
            </Form.Item>
              </Input.Group>
        </Col>:<null/>}
        {loggedInUser &&  loggedInUser.level <= levelsConst['Team Lead'] && <Col span={24}>
        <Input.Group>
             <Form.Item label="Project Coordinator" name="project_coordinator"  
            //  rules={[{required: true, message: 'Please add a Project Coordinator!'}]}
             >
                     <Select
                       showSearch
                       allowClear
                       placeholder="Select Project Coordinator"
                       options={projectCoordinatorsNoPaginationList?projectCoordinatorsNoPaginationList.map((item) => ({
                       key: item.user_id,
                       value: item.user_id,
                       label: item.name,
                     })):null}
                      // onChange={handleProjectCoordinatorChange}
                     optionFilterProp="label" 
                       filterOption={(input, option) =>
                         String(option.label).toLowerCase().indexOf(input.toLowerCase()) >= 0}
                     >
                     </Select>
             </Form.Item>
               </Input.Group>
         </Col>}
         { user && loggedInUser && (user.groups && user.groups.includes('Admin') || loggedInUser.level === levelsConst['Team Lead']) && onGoingOffers && supportPersonList && 
            <Col span={24}>
              <Form.Item label="Support Person" name="support_person">
                <Select
                  showSearch
                  mode="multiple"
                  onChange={handleSelectSupportPerson}
                  filterOption={(input, option) =>
                    String(option.children).toLowerCase().includes(input.toLowerCase())
                  }
                  // onSearch={handleSupportPersonSearch}
                >
                  {selectedRecord && supportPersonList.map((obj) => {
                    const matchedSupports = selectedRecord.support.filter(
                      (item) => obj.id === item.support.id
                    );

                    const hasValidEndDate = matchedSupports.some(matchedSupport => {
                      const endDate = matchedSupport.end_date ? moment(matchedSupport.end_date) : null;
                      return endDate && endDate.isSameOrAfter(moment());
                    });

                    if (hasValidEndDate) {
                      return null;
                    }

                    return (
                      <Option key={obj.id} value={obj.id}>
                        {obj.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          }
            {supportPersonsListWithEndDate && supportPersonsListWithEndDate.length > 0 && 
              <Col span={24}  style={{paddingBottom:'10px', display:"flex"}}>
                <Col span={12}>Support Name WithEndDate</Col>
                <Col span={4}>Start Date</Col>
                <Col span={4}>End Date</Col>
                <Col span={4}>Rate</Col>
              </Col>
            }
            {supportPersonsListWithEndDate && supportPersonsListWithEndDate.map((record, index)=>(<>
              <StyledRow key={index} style={{ padding: 0, margin: 0, width:'100%'}}>
              <Col span={12} style={{ padding: 0, margin: 0 }}>
                <Form.Item name={`innit_supportName_${record.support.id}_${index}`} >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      String(option.children).toLowerCase().includes(input.toLowerCase())
                    }
                    disabled
                  >
                    {supportPersonList && supportPersonList.map((supportPerson)=>{
                      return (
                        <Option key={supportPerson.id} value={supportPerson.id}>
                          {supportPerson.name}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4} style={{ padding: 0, margin: 0 }}>
                <Form.Item name={`innit_start_date_${record.support.id}_${index}`} style={{ padding: 0, margin: 0 }}>
                  <DatePicker
                    disabled
                    format="DD/MM/YYYY"
                    autoComplete="off"
                    className="ant-input"
                    style={{ width: '100%' }}
                    onChange={(date, dateString) =>{
                      if(record && record.support && supportPersonsListWithEndDate){
                        supportPersonsListWithEndDate.map((items)=>{
                          if(items.support.id === record.support.id){
                            const updatedData = [...supportPersonsListWithEndDate]
                            updatedData.map((item)=>{
                              if(record.id === item.id){
                                item[`start_date`] = date && moment(date).format('YYYY-MM-DD')
                              }
                            })
                            setSupportPersonsListWithEndDate(updatedData)
                          }
                        })
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ padding: 0, margin: 0 }}>
                <Form.Item
                  name={`innit_end_date_${record.support.id}_${index}`} style={{ padding: 0, margin: 0 }}
                  rules={[{ required: true, message: 'End date is required!' }]}
                >
                  <DatePicker
                    disabledDate={(current) => current && current.isSameOrBefore(moment().startOf('day'))}
                    disabled={record.end_date && moment(record.end_date).isSameOrBefore(moment().startOf('day'))}
                    format="DD/MM/YYYY"
                    autoComplete="off"
                    className="ant-input"
                    style={{ width: '100%'}}
                    onChange={(date, dateString) =>{
                      if(record && record.support && supportPersonsListWithEndDate){
                        supportPersonsListWithEndDate.map((items)=>{
                          if(items.support.id === record.support.id){
                            const updatedData = [...supportPersonsListWithEndDate]
                            updatedData.map((item)=>{
                              if(record.id === item.id){
                                item[`end_date`] = date && moment(date).format('YYYY-MM-DD')
                              }
                            })
                            setSupportPersonsListWithEndDate(updatedData)
                          }
                        })
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={4} style={{ padding: 0, margin: 0 }}>
                <Form.Item name={`innit_rate_${record.support.id}_${index}`} >
                  <Input type="number" placeholder="Rate" disabled />
                </Form.Item>
              </Col>
            </StyledRow>
            </>
            )) 
          }
          {(seletedSupportPersonsList && seletedSupportPersonsList.length > 0) && <>
            <Col span={24}  style={{paddingBottom:'10px', display:"flex"}}>
              <Col span={12}>Support Name With out EndDate</Col>
              <Col span={4}>Start Date</Col>
              <Col span={4}>End Date</Col>
              <Col span={4}>Rate</Col>
            </Col>
            {seletedSupportPersonsList.map((record, index) => (
          <StyledRow key={index} style={{ padding: 0, margin: 0, width:'100%'}}>
            <Col span={12} style={{ padding: 0, margin: 0 }}>
              <Form.Item
                name={`supportName_${record.support.id}_${index}`}
                rules={[{ required: true, message: 'Support name is required!' }]}
              >
                <Select
                    showSearch
                    filterOption={(input, option) =>
                      String(option.children).toLowerCase().includes(input.toLowerCase())
                    }
                    disabled
                  >
                  </Select>
              </Form.Item>
            </Col>
            <Col span={4} style={{ padding: 0, margin: 0 }}>
              <Form.Item
                name={`start_date_${record.support.id}_${index}`}
                rules={[{ required: true, message: 'Start date is required!' }]}
                style={{ padding: 0, margin: 0 }}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  autoComplete="off"
                  className="ant-input"
                  style={{ width: '100%' }}
                  onChange={(date, dateString) => handleChangeInSelectedSupport(date, record, 'start_date')}
                  disabledDate={(current) => {
                    const matchingSupport = supportPersonsListWithEndDate && supportPersonsListWithEndDate.find(
                      (obj) => obj.support.id === record.support.id
                    );

                  const endDate = form.getFieldValue(`end_date_${record.support.id}_${index}`);

                    if (matchingSupport && matchingSupport.end_date) {
                      if(endDate){
                        return current && (current.isBefore(moment(matchingSupport.end_date).endOf('day'))) ||
                          current.isAfter(moment(endDate).endOf('day'))
                      }else{
                        return current && (current.isBefore(moment(matchingSupport.end_date).endOf('day')));
                      }
                    }

                    if (endDate) {
                      return current && current.isAfter(moment(endDate).endOf('day'));
                    }

                  const matchedSupport = supportPersonList && supportPersonList.find((item) => item.id === record.id);
                    if (matchedSupport && matchedSupport.hired_date) {
                      return current && current.isBefore(moment(matchedSupport.hired_date).endOf('day'));
                    }
                    return false;
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={4} style={{ padding: 0, margin: 0 }}>
              <Form.Item
                name={`end_date_${record.support.id}_${index}`}
                style={{ padding: 0, margin: 0 }}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  autoComplete="off"
                  className="ant-input"
                  style={{ width: '100%' }}
                  onChange={(date, dateString)=>handleChangeInSelectedSupport(date, record, `end_date` )}
                  disabledDate={(current) => {
                    const matchingSupport = supportPersonsListWithEndDate && supportPersonsListWithEndDate.find(
                      (obj) => obj.support.id === record.support.id
                    );
                    const startDate = form.getFieldValue(`start_date_${record.support.id}_${index}`);
                    if (matchingSupport) {
                      if (matchingSupport.end_date) {                        
                        return (
                          current &&
                          (current.isBefore(moment(matchingSupport.end_date).endOf('day')) ||
                          (startDate && current.isBefore(startDate)))
                        );
                      }else if(startDate){
                        current.isBefore(moment(startDate).endOf('day'))
                      }else {
                        return true;
                      }
                    }
                    else {
                      const matchedSupport = supportPersonList && supportPersonList.find((item) => item.id === record.id);
                      if (matchedSupport && matchedSupport.hired_date) {
                        return current && current.isBefore(moment(matchedSupport.hired_date).endOf('day')) || startDate && current.isBefore(startDate);
                      }
                    }
                    return startDate && current.isBefore(startDate);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={4} style={{ padding: 0, margin: 0 }}>
              <Form.Item
                name={`rate_${record.support.id}_${index}`}
                rules={[{ required: true, message: 'Rate is required!' }]}
                onChange={(e)=>handleChangeInSelectedSupport(e.target.value, record, `rate` )}
              >
                <Input type="number" placeholder="Rate" />
              </Form.Item>
            </Col>
          </StyledRow>
        ))}
            </>
            }

        {loggedInUser &&  loggedInUser.level <= levelsConst['Floor Manager'] && <Col span={24}>
        <Form.Item label='Payroll Cost' 
                   name={"payroll_cost"}>
          <Input type="number" autoComplete="off" min={0} />
        </Form.Item> 
        </Col>}
        {loggedInUser &&  loggedInUser.level <= levelsConst['Floor Manager'] &&
         <Col span={24}>
        <Form.Item label='Support Rate'
                   name={"support_rate"}>
          <Input type="number" autoComplete="off" min={0} />
        </Form.Item> 
        </Col>}
        {user && user.groups && user.groups.includes(groups['Admin']) && 
        <Col span={24}>
        <Form.Item label='Device handling rate'
                   name={"device_handling_rate"}>
          <Input type="number" autoComplete="off" min={0} />
        </Form.Item> 
        </Col>} 
        {loggedInUser &&  loggedInUser.level <= levelsConst['Floor Manager'] && 
        <Col span={24}>
        <Form.Item label='Consultant Rate'
                   name={"consultant_rate"}>
          <Input type="number" autoComplete="off" min={0} />
        </Form.Item> 
        </Col>}
        {loggedInUser &&  loggedInUser.level <= levelsConst['Floor Manager'] &&<Col span={24}>
        <Form.Item label='Consultant Payroll Tax Term' name={"payroll_tax_type"} >
              <Select>
                <Option key={'w2'} value={'w2'}>W2</Option>
                <Option key={'1099-b'} value={'1099-b'}>1099-Business</Option>
                <Option key={'1099-i'} value={'1099-i'}>1099-Individual</Option> 
              </Select>
            </Form.Item>    
        </Col>}
          {user && user.groups && user.groups.includes('Admin') && <Col span={24}>
            <Form.Item label='Inhouse engineer rate'
                      name={"inhouse_engg_rate"}>
              <Input type="number" autoComplete="off" min={0} />
            </Form.Item> 
          </Col>}
         <Col span={24}>
            <Form.Item label='Tax Term with Vendor' name={"tax_term"} >
              <Select>
                <Option key={'w2'} value={'w2'}>W2</Option>
                <Option key={'c2c'} value={'c2c'}>C2C</Option>
                <Option key={'1099'} value={'1099'}>1099</Option>
              </Select>
            </Form.Item>
        </Col>
        <Col span={24}>
            <Form.Item label="Remarks" name="remark">
                <TextArea rows={2} placeholder="Project Remarks" autoComplete="off"/>
            </Form.Item>
        </Col>

      <Col span={24}>
      <Form.Item label='Technologies'
                name={"technologies"}>
        <TextArea rows={2} placeholder="Python, C++, AWS" autoComplete="off"/>
      </Form.Item>
      </Col>

      {title === "Approve Offer" && 
        <Col span={24}>
        <Form.Item label='Project start date acknowledged'
                  name={"project_start_date_acknowledged"}>
          <Checkbox rows={2} onChange={handleCheckBox} />
        </Form.Item>
        </Col>
      }
      <Col span={24}>
        <Form.Item label='Can consultant See this offer ? '
            name={"consultant_can_view"} valuePropName="checked" >
          <Checkbox value={showOfferToconsultant} onChange={handleShowOfferToConsultantCheckBox} />
        </Form.Item>
      </Col>

    </Form>
  </Modal>
  )
}

export default EditOfferForm