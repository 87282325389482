import React, {memo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import URLSearchParams from 'url-search-params'
import {Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router";
import {ConfigProvider} from 'antd';
import {IntlProvider} from "react-intl";

import AppLocale from "../../lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import ForgotPassword from "../ForgotPassword";
import ForgotSuccess from "../ForgotSuccess";
import {setInitUrl} from "../../appRedux/actions/Auth";
import {RestrictedRoute} from "../../util/routeRestriction"
import {onLayoutTypeChange, onNavStyleChange, setThemeType} from "../../appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";
import {getUser} from "../../appRedux/actions";
import Onboarding from "../../routes/onboarding";
import Background from "../../routes/background";


const App = (props) => {

  const dispatch = useDispatch();
  const {locale, navStyle, layoutType} = useSelector(({settings}) => settings);
  const {initURL, isAuthenticated, authUser} = useSelector(({auth}) => auth);
  const loggedInUser = useSelector(({user}) => user.loggedInUser);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if(authUser && !loggedInUser){
      dispatch(getUser());
    }
  }, [dispatch, authUser, loggedInUser]);


  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  });


  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  const setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  useEffect(() => {
    if (location.pathname === '/') {
      if (!isAuthenticated) {
        history.push('/signin');
      } else if (initURL === '' || initURL === '/' || initURL === '/signin' || initURL === '/login') {
        let path = '/username/dashboard'.replace('username', authUser.username);
        if(authUser.groups.some(r => ['Project Coordination'].indexOf(r) >= 0)){ // this is added for the project coordinator only
          path = '/username/dashboard'.replace('username', authUser.username);
        }
        if(authUser.groups.some(r => ['Human Resource'].indexOf(r) >= 0)){
          path = '/username/human_resource/marketer/list'.replace('username', authUser.username);
        }
        if (authUser.groups.some(r => ['Admin'].indexOf(r) >= 0)) {
          path = '/username/dashboard/recruitment'.replace('username', authUser.username);
        }
        history.push(path);
      } else {
        history.push(initURL);
      }
    }
  }, [isAuthenticated, authUser, initURL, location, history]);

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>

        <Switch>
          <Route exact path='/signin' component={SignIn}/>
          <Route exact path='/signup' component={SignUp}/>
          <Route exact path='/forgot-password' component={ForgotPassword}/>
          <Route path="/password-reset/confirm/:path*" component={ForgotSuccess} />
          <Route exact path='/onboarding-form/:path*' component={Onboarding}/>
          <Route exact path='/background-verification/:path*' component={Background}/>
          <RestrictedRoute path={`${match.url}`}
                           isAuthenticated={isAuthenticated}
                           authUser={loggedInUser ? loggedInUser : authUser}
                           location={location}
                           dispatch={dispatch}
                           component={MainApp}/>
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  )
};

export default memo(App);
